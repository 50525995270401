import React, { useState, useEffect } from 'react';
import axios from 'axios';
import GameTable from './components/GameTable';
import './App.css';

const App = () => {
  const [games, setGames] = useState([]);

  const fetchGames = async () => {
    try {
      const response = await axios.get('https://apibinhthanh.buscoffeeandtea.com.vn/api/tv/games');
      setGames(response.data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchGames();

    // Refresh the page every 5 minutes (300,000 milliseconds)
    const refreshInterval = setInterval(() => {
      window.location.reload();
    }, 60000);

    return () => clearInterval(refreshInterval);
  }, []);

  return (
      <div className="App">
        <header className="App-header">
          <img src="/logo.png" alt="Bus Coffee and Tea Logo" className="App-logo"/>
        </header>
        <GameTable games={games} fetchGames={fetchGames}/>
        <footer className="App-banner">
          <img src="/banner.png" alt="Banner" className="Banner-image"/>
        </footer>
      </div>
  );
};

export default App;
