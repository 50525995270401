import React, { useState, useEffect } from 'react';
import './GameTable.css';

const GameTable = ({ games, fetchGames }) => {
    const calculateRemainingTime = (startTime, levelTime, dealer) => {
        if (!dealer) {
            return NaN;
        }
        const now = new Date();
        const start = new Date(startTime);
        if (start > now) {
            return NaN;
        }
        const elapsed = Math.floor((now - start) / 1000);
        const remaining = levelTime * 60 - (elapsed % (levelTime * 60));
        return remaining > 0 ? remaining : 0;
    };

    const formatStartTime = (startTime) => {
        const date = new Date(startTime);
        const options = { year: 'numeric', month: 'short', day: 'numeric', hour: '2-digit', minute: '2-digit' };
        return date.toLocaleString('en-US', options);
    };

    const formatTime = (seconds) => {
        if (isNaN(seconds)) {
            return "00:00";
        }
        const minutes = Math.floor(seconds / 60);
        const secs = seconds % 60;
        return `${String(minutes).padStart(2, '0')}:${String(secs).padStart(2, '0')}`;
    };

    const [timers, setTimers] = useState([]);

    useEffect(() => {
        if (games.length > 0) {
            const initialTimers = games.map(game => calculateRemainingTime(game.start_time, game.level_time, game.dealer));
            setTimers(initialTimers);
            console.log('Initial timers:', initialTimers);
        }
    }, [games]);

    useEffect(() => {
        const interval = setInterval(() => {
            setTimers(prevTimers => prevTimers.map((timer, index) => {
                const game = games[index];
                if (game.dealer && timer > 0) {
                    return calculateRemainingTime(game.start_time, game.level_time, game.dealer);
                } else if (timer === 0) {
                    fetchGames();
                    return calculateRemainingTime(game.start_time, game.level_time, game.dealer);
                } else {
                    return NaN;
                }
            }));
        }, 1000);

        return () => clearInterval(interval);
    }, [games, fetchGames]);

    return (
        <table className="game-table">
            <thead>
            <tr>
                <th>ID GAME</th>
                <th>Table</th>
                <th>Game Mode</th>
                <th>Start Time</th>
                <th>Current Level</th>
                <th>Small Blind</th>
                <th>Big Blind</th>
                <th>Ante</th>
                <th>Timer</th>
                <th>Dealer</th>
            </tr>
            </thead>
            <tbody>
            {games.map((game, index) => (
                <tr key={game.id}>
                    <td>{game.id}</td>
                    <td>{game.room_id}</td>
                    <td>{game.mode}</td>
                    <td>{formatStartTime(game.start_time)}</td>
                    <td>{game.level}</td>
                    <td>{game.small_blind}</td>
                    <td>{game.big_blind}</td>
                    <td>{game.ante}</td>
                    <td>{formatTime(timers[index])}</td>
                    <td>{game.dealer ? game.dealer.name : ''}</td>
                </tr>
            ))}
            </tbody>
        </table>
    );
};

export default GameTable;
